import React from "react"

const Footer = () => {
    return (
        <div className="text-center my-2 italic pb-6">
            All images, text are copyright to Big Bad Robots Sdn Bhd unless otherwise mentioned.
            <br/>
            <a href="https://www.freepik.com/vectors/business">Business vector created by macrovector_official - www.freepik.com</a>
        </div>
    )
}

export default Footer;